import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import MyModal from "../components/MyModal"
import Header from "../components/Header"

import image1sm from "../images/community-support/sm/image1.jpg"
import image2sm from "../images/community-support/sm/image2.jpg"
import image3sm from "../images/community-support/sm/image3.jpg"
import image4sm from "../images/community-support/sm/image4.jpg"

import image1lg from "../images/community-support/lg/image1.jpg"
import image2lg from "../images/community-support/lg/image2.jpg"
import image3lg from "../images/community-support/lg/image3.jpg"
import image4lg from "../images/community-support/lg/image4.jpg"

class Community extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false
    }
  }

  showImage1 = () => {
    this.setState({showModal: true, modalContent: <img style={{width: "100%"}} src={image1lg} alt="Community Support"></img>})
  }

  showImage2 = () => {
    this.setState({showModal: true, modalContent: <img style={{width: "100%"}} src={image2lg} alt="Community Support"></img>})
  }

  showImage3 = () => {
    this.setState({showModal: true, modalContent: <img style={{width: "100%"}} src={image3lg} alt="Community Support"></img>})
  }

  showImage4 = () => {
    this.setState({showModal: true, modalContent: <img style={{width: "100%"}} src={image4lg} alt="Community Support"></img>})
  }

  render () {
    const { location } = this.props
    let modalClose = () => this.setState({showModal: false});
    return(
      <Layout location={location}>
        <SEO title="Community Support" />
        <Header />
        <section className="container page">
          <div className="row">
            <div className="col-12 text-center">
              <h1>Community Support</h1>
              <p>Precision PDR likes to support local charities and sporting groups, especially junior programs, as they are the future.</p>
              <p>South Coast United Mountainbikers, Bomaderry Junior Cricket, Shoalhaven United Soccer Club as well as the great work done by the N.S.W. Variety Bash, Shoalhaven Variety Bash Team, M.S. Sydney to Gong ride to name a few.</p>
              <p> We are very fortunate to live and work in such an amazing area and like to continue to help it grow and prosper.</p>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-3 col-md-6">
              <img src={image1sm} className="img-fluid img-thumbnail img-support" alt="Community Support" onClick={this.showImage1}></img>
            </div>
            <div className="col-xl-3 col-md-6">
              <img src={image2sm} className="img-fluid img-thumbnail img-support" alt="Community Support" onClick={this.showImage2}></img>
            </div>
            <div className="col-xl-3 col-md-6">
              <img src={image3sm} className="img-fluid img-thumbnail img-support" alt="Community Support" onClick={this.showImage3}></img>
            </div>
            <div className="col-xl-3 col-md-6">
              <img src={image4sm} className="img-fluid img-thumbnail img-support" alt="Community Support" onClick={this.showImage4}></img>
            </div>
          </div>
        </section>
        <MyModal 
          showModal={this.state.showModal}
          onHide={modalClose}
          content={this.state.modalContent}
        />
      </Layout>
    )
  }
}

export default Community;